import type {LocalDate} from '@js-joda/core';
import {formatDate} from '@thx/date';
import {Icon, List, ListItem, Popup} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as gstRoutes} from '../../../../gst/routes';
import {gstStatus} from '../lib';

interface Props {
	accountInfoId: string;
	gst?: LocalDate | null;
	fiscalStart?: LocalDate | null;
	fiscalEnd?: LocalDate | null;
	yearEnd?: LocalDate | null;
	borderRight?: boolean;
}

export function GstStatusCell({gst, fiscalStart, fiscalEnd, accountInfoId, borderRight}: Props) {
	return (
		<Popup
			content={
				<List>
					<ListItem>
						<Icon
							name="circle"
							color={gstStatus({
								gstDate: gst,
								fiscalStart,
								fiscalEnd,
								quarter: 1,
							})}
						/>
						GST last filed on {formatDate(gst)}
					</ListItem>
				</List>
			}
			trigger={
				<LinkedTableCell
					to={gstRoutes.to.gst({accountInfoId})}
					style={{color: '#121212', borderRight: borderRight ? '1px solid #dcdcdc' : undefined, textAlign: 'center'}}
					value={
						<>
							{formatDate(gst)}
							<div style={{margin: -8}}>
								<Icon
									name="circle"
									size="small"
									color={gstStatus({
										gstDate: gst,
										fiscalStart,
										fiscalEnd,
										quarter: 1,
									})}
									style={{padding: 0, margin: 0}}
								/>
								<Icon
									name="circle"
									size="small"
									color={gstStatus({
										gstDate: gst,
										fiscalStart,
										fiscalEnd,
										quarter: 2,
									})}
									style={{padding: 0, margin: 0}}
								/>
								<Icon
									name="circle"
									size="small"
									color={gstStatus({
										gstDate: gst,
										fiscalStart,
										fiscalEnd,
										quarter: 3,
									})}
									style={{padding: 0, margin: 0}}
								/>
								<Icon
									name="circle"
									size="small"
									color={gstStatus({
										gstDate: gst,
										fiscalStart,
										fiscalEnd,
										quarter: 4,
									})}
									style={{padding: 0, margin: 0}}
								/>
							</div>
						</>
					}
					selectable
					linkStyle={{paddingTop: 0, marginTop: 5}}
				/>
			}
		/>
	);
}
