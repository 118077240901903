import {LocalDate} from '@js-joda/core';

interface T2Props {
	t2FiledYear?: number | null;
	fiscalStart?: LocalDate | null;
	fiscalEnd?: LocalDate | null;
}

export function t2Status({t2FiledYear, fiscalStart, fiscalEnd}: T2Props) {
	if (!fiscalStart) return 'grey';
	if (!fiscalEnd) return 'grey';

	if (fiscalEnd.year() === t2FiledYear) return 'green';
	if (fiscalEnd.plusMonths(5).isAfter(LocalDate.now())) return 'grey';

	return 'yellow';
}
