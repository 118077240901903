import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editDocumentTypeForm.graphql';

const defaultOptions = {} as const;
export type EditDocumentTypeFormMutationVariables = Types.Exact<{
	data: Types.EditDocumentTypeInput;
}>;

export type EditDocumentTypeFormMutation = {editDocumentType?: {id: string; version: number} | null};

export type EditDocumentTypeFormType = {id: string; version: number};

export type EditDocumentTypeFormMutationFn = Apollo.MutationFunction<EditDocumentTypeFormMutation, EditDocumentTypeFormMutationVariables>;

/**
 * __useEditDocumentTypeFormMutation__
 *
 * To run a mutation, you first call `useEditDocumentTypeFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDocumentTypeFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDocumentTypeFormMutation, { data, loading, error }] = useEditDocumentTypeFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditDocumentTypeFormMutation(
	baseOptions?: Apollo.MutationHookOptions<EditDocumentTypeFormMutation, EditDocumentTypeFormMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditDocumentTypeFormMutation, EditDocumentTypeFormMutationVariables>(Operations, options);
}
export type EditDocumentTypeFormMutationHookResult = ReturnType<typeof useEditDocumentTypeFormMutation>;
export type EditDocumentTypeFormMutationResult = Apollo.MutationResult<EditDocumentTypeFormMutation>;
export type EditDocumentTypeFormMutationOptions = Apollo.BaseMutationOptions<EditDocumentTypeFormMutation, EditDocumentTypeFormMutationVariables>;
