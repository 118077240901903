import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Input, Header} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {useAccountState} from '../../../accounts/state';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes} from '../../routes';
import {setReceiptsFilter, clearReceiptsFilter, useAccountingState} from '../../state';
import {PaymentReceiptGlAccountDropdown} from '../PaymentReceiptGlAccountDropdown';

export function ReceiptsTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.receipts>();
	const {fiscalOpenDate, receiptsFilter} = useAccountingState();
	const {yearEnd} = useAccountState();
	const {endDate} = useCommonState();
	const {invoiceNumber, receiptGlAccount, vendor, fromAmount, toAmount} = receiptsFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();

	return (
		<List>
			{(!yearEnd || !fiscalOpenDate || (yearEnd && fiscalOpenDate && !endDate.isBefore(fiscalOpenDate))) && (
				<>
					<List.Item>
						<Button icon="add" content="Create Receipt" positive fluid onClick={() => push(routes.to.createReceipt({accountInfoId}))} />
					</List.Item>
					<Divider />
				</>
			)}
			<Header size="small">Filter Receipts</Header>
			<List.Item>
				<Label>Receipt GL Account</Label>
				<PaymentReceiptGlAccountDropdown
					hideReceivablesGl
					clearable
					fluid
					accountInfoId={accountInfoId}
					value={receiptGlAccount || ''}
					onChange={(e, {value}) => {
						dispatch(setReceiptsFilter({receiptGlAccount: typeof value === 'string' ? value : undefined}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>Customer</Label>
				<VendorDropdown
					onChange={val => dispatch(setReceiptsFilter({vendor: val}))}
					clearable
					fluid
					tabIndex={0}
					value={vendor || ''}
					accountInfoId={accountInfoId}
				/>
			</List.Item>
			<List.Item>
				<Label>Invoice Number</Label>
				<Input
					value={invoiceNumber || ''}
					fluid
					onChange={e => {
						const val = e.target.value;
						dispatch(setReceiptsFilter({invoiceNumber: val}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setReceiptsFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput fluid placeholder="To Amount" value={toAmount} onChange={value => value && dispatch(setReceiptsFilter({toAmount: value}))} />
			</List.Item>
			<List.Item>
				<Button content="Clear Filters" onClick={() => dispatch(clearReceiptsFilter())} />
			</List.Item>
		</List>
	);
}
