/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import debug from 'debug';
import {ReminderFilter} from '~core/graphql';

const d = debug('tacs.web.user.state');

export const state = createSlice({
	name: 'user',
	initialState: {
		userId: null as string | null,
		userEventFeatureFilter: null,
		viewUserEventDetails: false,
		userEventOffsetFilter: 0,
		userName: null as string | null,
		activeAgentId: null as string | null,
		organizationId: null as string | null,
		organizationName: null as string | null,
		officeId: null as string | null,
		reminderType: ReminderFilter.Upcoming,
	},
	reducers: {
		setUserId: (st, action: PayloadAction<string | null>) => {
			st.userId = action.payload;
		},
		setUserName: (st, action: PayloadAction<string | null>) => {
			st.userName = action.payload;
		},
		setOfficeId: (st, action: PayloadAction<string | null>) => {
			st.officeId = action.payload;
		},
		setActiveAgentId: (st, action: PayloadAction<string | null>) => {
			st.activeAgentId = action.payload;
		},
		setOrganizationId: (st, action: PayloadAction<string | null>) => {
			st.organizationId = action.payload;
		},
		setOrganizationName: (st, action: PayloadAction<string | null>) => {
			st.organizationName = action.payload;
		},
		setReminderType: (st, action: PayloadAction<ReminderFilter>) => {
			st.reminderType = action.payload;
		},
		setUserEventFeatureFilter: (st, action) => {
			st.userEventFeatureFilter = action.payload;
		},
		setUserEventOffsetFilter: (st, action: {payload: number}) => {
			st.userEventOffsetFilter = action.payload;
		},
		setViewUserEventDetails: (st, action: {payload: boolean}) => {
			st.viewUserEventDetails = action.payload;
		},
	},
});

export const useUserState = createSliceHook(state);

export const {
	setReminderType,
	setUserName,
	setActiveAgentId,
	setUserId,
	setOrganizationId,
	setOrganizationName,
	setOfficeId,
	setUserEventFeatureFilter,
	setUserEventOffsetFilter,
	setViewUserEventDetails,
} = state.actions;
