import {TForm} from '@thx/controls';
import {toast} from 'react-toastify';
import {Form, FormField, FormInput, Button, Dropdown} from 'semantic-ui-react';
import {object, string} from 'yup';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {useGetAllAccountsByOfficeQuery} from './getAllAccountsByOffice';
import {useMoveFileInfoToAccountInfoIdMutation} from './moveFileInfoToAccountInfoId';

interface MoveFileProps {
	accountInfoId: string;
	fileInfoId: string;
	onSave: () => void;
	managerId: string;
}

const moveFileFormValidation = object().shape({
	accountInfoId: string().required('The account info is required.'),
});

interface MoveFileFormType {
	accountInfoId: string;
}

export function MoveFile(props: MoveFileProps) {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {fileInfoId, onSave} = props;
	const {data, error, loading} = useGetAllAccountsByOfficeQuery({variables: {managerId: props.managerId}});
	const options = data?.getAllAccountsByManager?.map(accountInfo => {
		return {key: accountInfo?.id, value: accountInfo?.id, text: `${accountInfo?.friendlyId} - ${accountInfo?.name}`};
	});
	const [moveMutation, {loading: mutationLoading}] = useMoveFileInfoToAccountInfoIdMutation();

	if (error) throwError(error);

	const handleFormSubmit = (data1: MoveFileFormType) => {
		// run mutation
		moveMutation({
			variables: {
				accountInfoId: data1.accountInfoId,
				fileInfoId,
			},
		})
			.then(() => {
				toast.success('File moved');
				onSave();
			})
			.catch(error1 => throwError(error1));
	};

	return (
		<>
			<h4>Move file to another account</h4>
			<TForm<MoveFileFormType> initialValues={{accountInfoId: ''}} onSubmit={handleFormSubmit} validationSchema={moveFileFormValidation}>
				{({handleSubmit, hasWarnings, hasErrors, values, setFieldValue, fieldError}) => {
					return (
						<Form onSubmit={handleSubmit} warning={hasWarnings} error={hasErrors}>
							<FormInput required label="Account">
								<Dropdown
									name="accountInfoId"
									fluid
									search
									button
									options={options}
									loading={loading}
									disabled={loading || locked}
									error={fieldError('accountInfoId')}
									value={values.accountInfoId}
									onChange={(e, val) => setFieldValue('accountInfoId', val.value)}
								/>
							</FormInput>
							<FormField>
								<Button type="submit" content="Submit" color="green" disabled={loading || mutationLoading || locked} />
							</FormField>
						</Form>
					);
				}}
			</TForm>
		</>
	);
}
