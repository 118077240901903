import type {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TableCell} from 'semantic-ui-react';
import '~common/components/LinkedTableCell/styles.css';

interface Props {
	to?: string;
	active?: boolean;
	value?: string | JSX.Element | null | number;
	inverted?: boolean | null;
	disabled?: boolean | null;
	textAlign?: 'center' | 'left' | 'right';
	colSpan?: string | number | null;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: ReactNode;
	className?: string;
	collapsing?: boolean;
	selectable?: boolean;
	style?: React.CSSProperties;
	linkStyle?: React.CSSProperties;
	onClick?: () => void;
}

export function LinkedTableCell({disabled, inverted, to, target, children, value, onClick, linkStyle, ...rest}: Props) {
	const linkClasses = ['tableCellLink', disabled && 'tableCellLinkDisabled', inverted && 'tableCellLinkInverted'].filter(c => !!c).join(' ');

	const link = to ? (
		<Link target={target} to={to} className={linkClasses} onClick={onClick} style={linkStyle}>
			{value || children || <br />}
		</Link>
	) : (
		<span className={linkClasses}>{value || children || <br />}</span>
	);

	return (
		<TableCell {...rest} inverted={inverted?.toString()} disabled={disabled || false}>
			{link}
		</TableCell>
	);
}
