import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {GstFilingFrequencyDropdown} from '../GstFilingFrequencyDropdown';
import {useEditSelfEmployedAccountFilingFrequencyMutation} from './editAccountFilingFrequency';
import {useGetEditFilingFrequencyLazyQuery} from './getEditFilingFrequency';

const d = debug('tacs.web.accounts.components.accounts.EditFilingFrequency');

export function EditFilingFrequency() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditSelfEmployedAccountFilingFrequencyMutation();
	const [getQuery, {data, loading, error}] = useGetEditFilingFrequencyLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {gstFilingFrequency: data?.getGstFilingFrequency},
		onSubmit: formValues => {
			editMutation({
				variables: {
					accountInfoId,
					gstFilingFrequency: formValues.gstFilingFrequency,
				},
				refetchQueries: ['getSelfEmployedAccountInfoInformation', 'getCorporateAccountByAccountInfoId'],
			})
				.then(() => {
					toast.success('Filing frequency saved.');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getGstFilingFrequency) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Filing Frequency">
					<GstFilingFrequencyDropdown
						selection
						fluid
						onChange={val => setFieldValue('gstFilingFrequency', val)}
						value={values.gstFilingFrequency || undefined}
					/>
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					disabled={mutationLoading || locked}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" disabled={locked} onClick={() => setEditClicked(true)}>
			Edit Filing Frequency
		</Button>
	);
}
