import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission} from '~lib/permissionHelpers';
import {TicketManagementSidebar} from './components/TicketManagementSidebar';
import {TicketSidebar} from './components/TicketSidebar/TicketSidebar';
import {routes} from './routes';

const UserTicketsTable = loadable(() => import('./components/UserTicketsTable'));
const TicketManagementTable = loadable(() => import('./components/TicketManagementTable'));
const TicketCategoryManagementTable = loadable(() => import('./components/TicketCategoryManagementTable'));
const CreateTicketForm = loadable(() => import('./components/CreateTicketForm'));
const CreateTicketCategoryForm = loadable(() => import('./components/CreateTicketCategoryForm'));
const EditTicketCategoryForm = loadable(() => import('./components/EditTicketCategoryForm'));
const ViewTicketDetails = loadable(() => import('./components/ViewTicketDetails'));

export const routeProps = createPages(routes, {
	support: {
		header: 'Support',
		content: () => <UserTicketsTable />,
		sidebar: [
			{
				to: routes.to.createTicket(),
				color: 'blue',
				text: 'Create Ticket',
			},
		],
	},
	ticketManagement: {
		header: 'Ticket Management',
		content: () => <TicketManagementTable />,
		sidebar: [
			{
				render: () => <TicketManagementSidebar />,
			},
		],
	},
	ticketCategoryManagement: {
		header: 'Ticket Category Management',
		content: () => <TicketCategoryManagementTable />,
		sidebar: [
			{
				to: routes.to.createTicketCategory(),
				color: 'blue',
				text: 'Create Ticket Category',
			},
		],
	},
	createTicket: {
		header: 'Create Ticket',
		content: () => <CreateTicketForm />,
	},
	createTicketCategory: {
		header: 'Create Ticket Category',
		content: () => <CreateTicketCategoryForm />,
	},
	editTicketCategory: {
		header: 'Edit Ticket Category',
		content: () => <EditTicketCategoryForm />,
	},
	viewTicketDetails: {
		header: 'Ticket Details',
		content: () => <ViewTicketDetails />,
		sidebar: [{visible: {...isLoggedIn(), ...needsPermission(Permission.Sysadmin)}, render: () => <TicketSidebar />}],
	},
});
