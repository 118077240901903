import {useCan} from '@imperium/auth-client';
import {TForm} from '@thx/controls';
import debug from 'debug';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Divider, Form, FormInput} from 'semantic-ui-react';
import {GeneralLedgerAccountsDropdown} from '~common/components/GeneralLedgerAccountsDropdown';
import {FinancialAccountTypeEnum, GeneralLedgerAccountGroupEnum, LinkedGlAccountType, Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {FinancialAccountsDropdown} from '../../../../documents/components/statement/FinancialAccountsDropdown';
import {selectLinkedAccount} from '../../../util/selectLinkedAccount';
import type {GetChartOfAccountsLinkedType} from '../GeneralLedgerAccountsTableSideBar/getChartOfAccountsLinked';

const d = debug('tacs.web.accounting.components.chartOfAccounts.ChooseLinkedAccount');

interface ChooseLinkedAccountProps {
	selectionsFromBankAccounts?: boolean;
	glAccounts?: GetChartOfAccountsLinkedType['linkedAccounts'];
	type: LinkedGlAccountType;
	queryLoading: boolean;
	mutationLoading: boolean;
	name: string;
	group: GeneralLedgerAccountGroupEnum;
	accountInfoId: string;
	save?: (data: {type: LinkedGlAccountType; id: string}) => Promise<any>;
}

export function ChooseLinkedAccount({
	glAccounts,
	type,
	queryLoading,
	mutationLoading,
	name,
	group,
	accountInfoId,
	save,
	selectionsFromBankAccounts,
}: ChooseLinkedAccountProps) {
	const {locked} = useAccountState();
	const throwError = useAsyncError();
	const [editing, setEditing] = useState(false);
	const [sysadmin] = useCan(Permission.Sysadmin);

	const glAccount = selectLinkedAccount(type, glAccounts);

	const color = glAccount ? 'blue' : 'orange';
	const verb = glAccount ? 'Edit' : 'Choose';
	const chosenAccount = glAccount ? (
		<>
			<br />({glAccount.code} - {glAccount.name})
		</>
	) : null;

	const chooseButton = (
		<Button
			loading={queryLoading}
			fluid
			color={color}
			onClick={() => {
				setEditing(true);
			}}
			disabled={!sysadmin}
		>
			{verb} {name} {chosenAccount}
		</Button>
	);

	if (!editing) return chooseButton;

	return (
		<TForm<{glAccount: string}>
			initialValues={{glAccount: glAccount?.id}}
			onSubmit={values => {
				if (save) {
					save({
						id: values.glAccount,
						type,
					})
						.then(() => {
							toast.success('Linked account saved');
							setEditing(false);
						})
						.catch(throwError);
				} else {
					setEditing(false);
				}
			}}
		>
			{({handleSubmit, setFieldValue, values, fieldError}) => (
				<Form onSubmit={handleSubmit}>
					<Divider />
					<FormInput autoFocus error={fieldError('glAccount')} label={`Select ${name}`}>
						{selectionsFromBankAccounts ? (
							<FinancialAccountsDropdown
								passInGlAccIdAsValue
								value={values.glAccount}
								addNewFinancialAccountButton
								accountInfoId={accountInfoId}
								financialAccountsType={FinancialAccountTypeEnum.BankAccount}
								onChange={val => setFieldValue('glAccount', val?.glAccount?.id)}
							/>
						) : (
							<GeneralLedgerAccountsDropdown
								defaultGlAccountId={glAccount?.id}
								openOnly
								value={values.glAccount}
								onChange={val => setFieldValue('glAccount', val)}
								selection
								fluid
								accountInfoId={accountInfoId}
								accountGroups={[group]}
							/>
						)}
					</FormInput>
					<Button disabled={mutationLoading || locked} positive floated="right" type="submit">
						Save
					</Button>
					<Button type="button" onClick={() => setEditing(false)}>
						Cancel
					</Button>
					<Divider />
				</Form>
			)}
		</TForm>
	);
}
