import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getChartOfAccountsByAccountInfoId.graphql';

const defaultOptions = {} as const;
export type GetChartOfAccountsByAccountInfoIdQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.ChartOfAccountsGeneralLedgerAccountsFilterInput>;
}>;

export type GetChartOfAccountsByAccountInfoIdQuery = {
	getChartOfAccountsByAccountInfoId?: {
		id: string;
		version: number;
		glAccounts?: Array<{
			id: string;
			version: number;
			name: string;
			code: number;
			type: Types.GeneralLedgerAccountTypeEnum;
			closedDate?: LocalDate | null;
			generalLedgerAccountTemplate?: {id: string; description?: string | null} | null;
		}> | null;
		linkedAccounts: Array<{type: Types.LinkedGlAccountType; account?: {id: string; version: number; name: string; code: number} | null}>;
	} | null;
};

export type GetChartOfAccountsByAccountInfoIdType = {
	id: string;
	version: number;
	glAccounts?: Array<{
		id: string;
		version: number;
		name: string;
		code: number;
		type: Types.GeneralLedgerAccountTypeEnum;
		closedDate?: LocalDate | null;
		generalLedgerAccountTemplate?: {id: string; description?: string | null} | null;
	}> | null;
	linkedAccounts: Array<{type: Types.LinkedGlAccountType; account?: {id: string; version: number; name: string; code: number} | null}>;
};

/**
 * __useGetChartOfAccountsByAccountInfoIdQuery__
 *
 * To run a query within a React component, call `useGetChartOfAccountsByAccountInfoIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartOfAccountsByAccountInfoIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartOfAccountsByAccountInfoIdQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetChartOfAccountsByAccountInfoIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetChartOfAccountsByAccountInfoIdQuery, GetChartOfAccountsByAccountInfoIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetChartOfAccountsByAccountInfoIdQuery, GetChartOfAccountsByAccountInfoIdQueryVariables>(Operations, options);
}
export function useGetChartOfAccountsByAccountInfoIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetChartOfAccountsByAccountInfoIdQuery, GetChartOfAccountsByAccountInfoIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetChartOfAccountsByAccountInfoIdQuery, GetChartOfAccountsByAccountInfoIdQueryVariables>(Operations, options);
}
export type GetChartOfAccountsByAccountInfoIdQueryHookResult = ReturnType<typeof useGetChartOfAccountsByAccountInfoIdQuery>;
export type GetChartOfAccountsByAccountInfoIdLazyQueryHookResult = ReturnType<typeof useGetChartOfAccountsByAccountInfoIdLazyQuery>;
export type GetChartOfAccountsByAccountInfoIdQueryResult = Apollo.QueryResult<
	GetChartOfAccountsByAccountInfoIdQuery,
	GetChartOfAccountsByAccountInfoIdQueryVariables
>;
