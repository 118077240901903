import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useCreateTimeCardMutation} from './createTimeCard';

export function TimeCardCreateButton() {
	const throwError = useAsyncError();
	const {agentId} = useParams<typeof routes.types.timeCards>();
	const [createMutation, {loading: mutationLoading}] = useCreateTimeCardMutation();

	function handleSubmit() {
		createMutation({variables: {agentId}, refetchQueries: ['getTimeCards']})
			.then(() => {
				toast.success('New Time Card created.');
			})
			.catch(throwError);
	}
	return (
		<ConfirmModal
			onConfirm={handleSubmit}
			headerText="New Time Card"
			content="Are you sure you want to create a new time card?"
			trigger={
				<Button fluid color="green" compact>
					Create New Time Card
				</Button>
			}
		/>
	);
}
