import {TForm} from '@thx/controls';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Divider, Dropdown, Form, FormInput, List} from 'semantic-ui-react';
import {object, string} from 'yup';
import {EmployeePaymentIntervalEnum, employeePaymentIntervalEnumLookup, provinceEnumLookup} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {getProvinces} from '~lib/getProvinces';
import {useAsyncError} from '~lib/useAsyncError';
import {useCreateEmployeeMutation} from '../../lib/createEmployee';
import {getPaymentIntervalOptions} from '../../lib/getPaymentItervalOptions';
import {routes} from '../../routes';
import {useAccountState} from '../../state';

interface Props {
	contact: {
		id: string;
		version: number;
		givenName: string;
		surname: string;
		preferredName?: string | null;
	};
	accountInfoId: string;
}

const employeeAddSidebarContactListValidation = object().shape({
	homeTerminal: string().required('The home terminal is required.'),
	paymentInterval: string().required('The payment interval is required.'),
});

interface EmployeeAddSidebarContactListValidationSchemaType {
	homeTerminal: string;
	paymentInterval: string;
}

export function ContactList(props: Props) {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {contact, accountInfoId} = props;
	const {push} = useHistory();
	const [selectedContactId, setSelectedContactId] = useState('');

	const [createEmployeeMutation, {loading: mutationLoading}] = useCreateEmployeeMutation();

	const onCreateEmployeeClick = (contactId: string, paymentInterval: string, homeTerminal: string) => {
		const ht = provinceEnumLookup(homeTerminal);
		createEmployeeMutation({
			variables: {
				data: {
					accountInfoId,
					contactId,
					employeeData: {paymentInterval: employeePaymentIntervalEnumLookup(paymentInterval), homeTerminal: ht},
				},
			},
		})
			.then(() => push(routes.to.employees({accountInfoId})))
			.catch(throwError);
	};

	return (
		<List.Item key={contact.id}>
			{selectedContactId === contact.id ? (
				<TForm<EmployeeAddSidebarContactListValidationSchemaType>
					initialValues={{homeTerminal: 'MB', paymentInterval: EmployeePaymentIntervalEnum.Monthly12}}
					onSubmit={vals => onCreateEmployeeClick(contact.id, vals.paymentInterval, vals.homeTerminal)}
					validationSchema={employeeAddSidebarContactListValidation}
				>
					{tFormProps => {
						const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
						return (
							<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
								<Divider />
								<List>
									<List.Item>
										<FormInput autoFocus label="Home Terminal" required error={fieldError('homeTerminal')}>
											<Dropdown
												fluid
												selection
												options={getProvinces()}
												value={values.homeTerminal}
												onChange={(e, val) => setFieldValue('homeTerminal', val.value)}
											/>
										</FormInput>
										<FormInput label="Payment Interval" required error={fieldError('paymentInterval')}>
											<Dropdown
												fluid
												selection
												options={getPaymentIntervalOptions()}
												value={values.paymentInterval}
												onChange={(e, val) => setFieldValue('paymentInterval', val.value)}
											/>
										</FormInput>
									</List.Item>
									<List.Item>{renderWarnings()}</List.Item>
									<List.Item>
										<Button disabled={mutationLoading || locked} floated="right" positive type="submit">
											Save
										</Button>
										<Button type="button" floated="right" onClick={() => setSelectedContactId('')}>
											Cancel
										</Button>
									</List.Item>
								</List>
								<Divider />
							</Form>
						);
					}}
				</TForm>
			) : (
				<Button fluid color="blue" onClick={() => setSelectedContactId(contact.id)}>
					Add {getFullName(contact)} as an Employee
				</Button>
			)}
		</List.Item>
	);
}
