import {startCase} from 'lodash-es';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, Grid, GridColumn, GridRow, Table, TableBody, TableHeader, TableHeaderCell, TableRow} from 'semantic-ui-react';
import {ConfirmModal, Loading} from '~common/components';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {JournalEntryTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as accountingRoutes} from '../../../accounting/routes';
import {useAccountState} from '../../../accounts/state';
import {routes} from '../../routes';
import {useArchiveFinancialAccountByIdMutation} from './ViewFinancialAccount/archiveFinancialAccountById';
import {useGetFinancialAccountByIdQuery} from './ViewFinancialAccount/getFinancialAccountById';

export function getTemplateRoute(id: string, type: JournalEntryTypeEnum, accountInfoId: string) {
	switch (type) {
		case JournalEntryTypeEnum.Receivable:
			return accountingRoutes.to.editReceivableTemplate({accountInfoId, receivableTemplateId: id});
		case JournalEntryTypeEnum.Payable:
			return accountingRoutes.to.editPayableTemplate({accountInfoId, payableTemplateId: id});
		case JournalEntryTypeEnum.General:
			return accountingRoutes.to.editJournalEntryTemplate({accountInfoId, journalEntryTemplateId: id});
		default:
			return '';
	}
}

export default function ViewFinancialAccountSidebar() {
	const {locked} = useAccountState();
	const {accountInfoId, financialAccountId} = useParams<typeof routes.types.viewFinancialAccount>();
	const {push} = useHistory();
	const throwError = useAsyncError();

	const {data, error, loading} = useGetFinancialAccountByIdQuery({variables: {id: financialAccountId}});

	const [archiveMutation, {loading: mutationLoading}] = useArchiveFinancialAccountByIdMutation();

	if (error) throwError(error);
	if (loading || !data) return <Loading />;

	async function archive() {
		if (data?.getFinancialAccountById.financialAccount.id) {
			await archiveMutation({
				variables: {id: data?.getFinancialAccountById.financialAccount.id, version: data.getFinancialAccountById.financialAccount.version},
			})
				.then(() => {
					toast.success('Financial account archived.');
					push(routes.to.financialAccounts({accountInfoId}));
				})
				.catch(throwError);
		}
	}

	const accountLabel = startCase(data?.getFinancialAccountById.financialAccount.type);
	const noRunningBalance = !!data?.getFinancialAccountById.financialAccount.glAccount?.id && data.getFinancialAccountById.currentBalance?.isZero();
	const noLinkedTemplates = !!data?.getFinancialAccountsLinkedTemplates && data.getFinancialAccountsLinkedTemplates.length === 0;
	const isPlural = data.getFinancialAccountsLinkedTemplates && data.getFinancialAccountsLinkedTemplates.length > 1;

	const modalContent = () => {
		if (!noRunningBalance)
			return `There is a running balances on the General Ledger Account of this ${accountLabel}. It can be archived once the General Ledger Accounts carries a balance of zero.`;
		if (data.getFinancialAccountsLinkedTemplates) {
			return noLinkedTemplates ? (
				`Are you sure you want to archive this ${accountLabel}`
			) : (
				<Grid>
					<GridRow>
						<GridColumn>
							{`There ${isPlural ? 'are some templates' : 'is a template'} linked to this ${accountLabel} yet. Please edit or delete ${
								isPlural ? 'them' : 'it'
							}, before proceeding to archiving this ${accountLabel}.`}
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn>
							<Table selectable celled>
								<TableHeader>
									<TableRow>
										<TableHeaderCell>Type</TableHeaderCell>
										<TableHeaderCell>Description</TableHeaderCell>
										<TableHeaderCell />
									</TableRow>
								</TableHeader>
								<TableBody>
									{data.getFinancialAccountsLinkedTemplates.map(link => {
										return (
											<TableRow key={link.id}>
												<LinkedTableCell>
													{link.type === JournalEntryTypeEnum.General ? 'Journal Entry' : startCase(link.type)} Template
												</LinkedTableCell>
												<LinkedTableCell>{link.name} Template</LinkedTableCell>
												<LinkedTableCell to={getTemplateRoute(link.id, link.type, accountInfoId)} target="_blank">
													<Button>View</Button>
												</LinkedTableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</GridColumn>
					</GridRow>
				</Grid>
			);
		}
		return '';
	};

	const canArchive = noRunningBalance && noLinkedTemplates;

	return (
		<Form>
			<Form.Input>
				<Button
					loading={loading}
					fluid
					positive
					onClick={() => {
						push(routes.to.editFinancialAccount({accountInfoId, financialAccountId}));
					}}
				>
					{`Edit ${accountLabel}`}
				</Button>
			</Form.Input>
			<Form.Input>
				<ConfirmModal
					onConfirm={noLinkedTemplates && noRunningBalance ? archive : undefined}
					headerText={canArchive ? `Archive this ${accountLabel}` : `Unable to Archive this ${accountLabel}`}
					headerIcon={canArchive ? null : {name: 'warning', color: 'orange'}}
					content={modalContent()}
					trigger={
						<Button
							loading={loading}
							disabled={mutationLoading || data.getFinancialAccountById.financialAccount.archived || locked}
							fluid
							secondary
							content={`Archive ${accountLabel}`}
						/>
					}
				/>
			</Form.Input>
		</Form>
	);
}
