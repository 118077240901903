import {LocalDate} from '@js-joda/core';
import type {SemanticCOLORS} from 'semantic-ui-react';

interface GstProps {
	quarter: number;
	gstDate?: LocalDate | null;
	fiscalStart?: LocalDate | null;
	fiscalEnd?: LocalDate | null;
}
export function gstStatus({quarter, gstDate, fiscalStart, fiscalEnd}: GstProps): SemanticCOLORS {
	if (!fiscalStart) return 'grey';
	const lastYearEnd = fiscalEnd?.minusYears(1);

	if (gstDate && !lastYearEnd?.plusMonths(3 * quarter).isAfter(gstDate)) {
		return 'green';
	}
	if (gstDate && gstDate.plusMonths(3).isAfter(LocalDate.now().minusMonths(3))) return 'grey';

	return 'yellow';
}
