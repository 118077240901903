import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {BusinessNumberInput} from '~common/components/BusinessNumberInput';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useEditSelfEmployedBusinessNumberMutation} from './editBusinessNumber';
import {useGetEditBusinessNumberLazyQuery} from './getEditBusinessNumber';

const d = debug('tacs.web.accounts.components.accounts.EditBusinessNumber');

export function EditBusinessNumber() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditSelfEmployedBusinessNumberMutation();
	const [getQuery, {data, loading, error, called}] = useGetEditBusinessNumberLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {businessNumber: data?.getBusinessNumber},
		onSubmit: formValues => {
			editMutation({
				variables: {
					accountInfoId,
					businessNumber: formValues.businessNumber,
				},
				refetchQueries: ['getSelfEmployedAccountInfoInformation', 'getCorporateAccountByAccountInfoId'],
			})
				.then(() => {
					toast.success('Business number saved.');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getBusinessNumber && !called) {
			getQuery().catch(throwError);
		}
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus label="Business number">
					<BusinessNumberInput fluid value={values.businessNumber || ''} onChange={(val: string) => setFieldValue('businessNumber', val)} />
				</FormInput>
				<Button disabled={mutationLoading || locked} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					disabled={mutationLoading || locked}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" disabled={locked} onClick={() => setEditClicked(true)}>
			Edit Business Number
		</Button>
	);
}
