import type {LocalDate} from '@js-joda/core';
import {Icon, List, ListItem, Popup} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as corporateTaxesRoutes} from '../../../../corporateTaxes/routes';
import {t183Status, t2Status} from '../lib';

interface Props {
	accountInfoId: string;
	fiscalStart?: LocalDate | null;
	fiscalEnd?: LocalDate | null;
	yearEnd?: LocalDate | null;
	signedT183?: boolean | null;
	t2?: number | null;
}

export function T2StatusCell(accountData: Props) {
	return (
		<Popup
			content={
				<List>
					<ListItem>
						<Icon
							name="circle"
							color={t183Status({
								t183Signed: accountData.signedT183,
								t2FiledYear: accountData.t2,
								fiscalStart: accountData.fiscalStart,
								fiscalEnd: accountData.fiscalEnd,
							})}
						/>
						T183 Signed
					</ListItem>
					<ListItem>
						<Icon
							name="circle"
							color={t2Status({t2FiledYear: accountData.t2, fiscalStart: accountData.fiscalStart, fiscalEnd: accountData.fiscalEnd})}
						/>
						T2 filed (last on {accountData.t2})
					</ListItem>
				</List>
			}
			trigger={
				<LinkedTableCell
					to={corporateTaxesRoutes.to.corporateTaxes({accountInfoId: accountData.accountInfoId})}
					style={{color: '#121212', textAlign: 'center', borderRight: '1px solid #dcdcdc'}}
					value={
						<>
							{accountData.t2}
							<div style={{margin: -8}}>
								<Icon
									name="circle"
									size="small"
									color={t183Status({
										t183Signed: accountData.signedT183,
										t2FiledYear: accountData.t2,
										fiscalStart: accountData.fiscalStart,
										fiscalEnd: accountData.fiscalEnd,
									})}
									style={{padding: 0, margin: 0}}
								/>
								<Icon
									name="circle"
									size="small"
									color={t2Status({t2FiledYear: accountData.t2, fiscalStart: accountData.fiscalStart, fiscalEnd: accountData.fiscalEnd})}
									style={{padding: 0, margin: 0}}
								/>
							</div>
						</>
					}
					selectable
					linkStyle={{paddingTop: 0, marginTop: 5}}
				/>
			}
		/>
	);
}
