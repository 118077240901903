import {List, Divider} from 'semantic-ui-react';
import {CloneAccountButton} from './CloneAccountButton';
import {DeleteAccountButton} from './DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOffice} from './EditAccountOffice';
import {EditAccountOrganizationAndOffice} from './EditAccountOrganizationAndOffice';
import {EditAccountStatus} from './EditAccountStatus/EditAccountStatus';
import {EditDefaultAgent} from './EditDefaultAgent';

export function PersonalAccountInfoSidebar() {
	return (
		<List>
			<List.Header as="h3">Actions</List.Header>
			<Divider />
			<List.Item>
				<EditAccountName />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountStatus />
			</List.Item>
			<List.Item>
				<EditAccountOrganizationAndOffice />
			</List.Item>
			<List.Item>
				<EditAccountOffice />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<Divider />
			<List.Item>
				<CloneAccountButton />
			</List.Item>
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
