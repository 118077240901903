import type {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {sortBy} from 'lodash-es';
import React, {useMemo} from 'react';
import {Dropdown, DropdownItem, DropdownProps, Icon, Popup} from 'semantic-ui-react';
import {
	GetGeneralLedgerAccountsDropdownChartOfAccountsType,
	useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery,
} from '~common/components/GeneralLedgerAccountsDropdown/getGeneralLedgerAccountsDropdownChartOfAccounts';
import {
	ChartOfAccountsGeneralLedgerAccountsFilterInput,
	GeneralLedgerAccountGroupEnum,
	GeneralLedgerAccountTypeEnum,
	LinkedGlAccountType,
	UnArray,
} from '~core/graphql';
import type * as Types from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetChartOfAccountsLinkedQuery} from '../../../accounting/components/chartOfAccounts/GeneralLedgerAccountsTableSideBar/getChartOfAccountsLinked';
import {selectLinkedAccount} from '../../../accounting/util/selectLinkedAccount';

const d = debug('tacs.web.common.components.GeneralLedgerAccountsDropdown');

export type GeneralLedgerAccountSelection = UnArray<NonNullable<GetGeneralLedgerAccountsDropdownChartOfAccountsType['glAccounts']>>;

export type GlAccDropdownHandleChangeInput = string | string[] | undefined;
type UnavailableOptions = string | undefined;

interface GeneralLedgerAccountsDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: (val?: string | string[] | GeneralLedgerAccountSelection) => void;
	accountInfoId: string;
	defaultGlAccountId?: string;
	accountGroups?: GeneralLedgerAccountGroupEnum[];
	accountTypes?: GeneralLedgerAccountTypeEnum[];
	filterFunction?: (account: {
		id: string;
		version: number;
		name: string;
		code: number;
		type: Types.GeneralLedgerAccountTypeEnum;
		group: Types.GeneralLedgerAccountGroupEnum;
		generalLedgerAccountTemplate?: {id: string; description?: string | null} | null;
	}) => boolean;
	unavailableOptions?: UnavailableOptions[];
	inputGLAccObjectToOnChange?: boolean;
	hideGeneralJournalAccounts?: boolean;
	hideCurrentEarningAccounts?: boolean;
	minDate?: LocalDate | undefined;
	maxDate?: LocalDate | undefined;
	openOnly?: boolean;
}

/**
 * GeneralLedgerAccountsDropdown Component.
 *
 * A dropdown component for selecting general ledger accounts.
 *
 * @param {Object} props - The properties of the dropdown component.
 * @param {string} props.accountInfoId - Account info id
 * @param {string} props.defaultGlAccountId - Initial gl-account id.
 * Used if the gl-account should show up, although it is filtered out by other filters.
 * @param {Array<GeneralLedgerAccountGroupEnum>} [props.accountGroups] - A list of account groups
 * to filter available general ledger accounts.
 * If omitted, all account groups are considered.
 * @param {Array<GeneralLedgerAccountTypeEnum>} [props.accountTypes] - A list of gl-account types to filter.
 * @param {boolean} [props.hideGeneralJournalAccounts] - Flag to hide General Journal accounts from the dropdown.
 * @param {boolean} [props.hideCurrentEarningAccounts] - Flag to hide Current Earnings accounts from the dropdown.
 * @param {boolean} [props.openOnly] - Flag to show only open gl-accounts in the dropdown.
 * @param {Function} [props.filterFunction] - A custom filter function.
 * @param {Array<UnavailableOptions>} [props.unavailableOptions] - A list of gl-account IDs to exclude from the available options.
 * @param {boolean} [props.inputGLAccObjectToOnChange] - If `true`, the `onChange` callback will receive the full general ledger account object,
 *                                                     otherwise, it will receive the account ID(s) as strings.
 * @param {Function} [props.onChange] - A callback function triggered when an account is selected.
 *                                       It receives the selected account(s) as the argument,
 *                                       which could either be account ID(s)
 *                                       or the full account object depending on `inputGLAccObjectToOnChange`.
 * @param {DropdownProps} [props.rest] - Additional props passed directly to the `Dropdown` component
 *
 * @returns {JSX.Element} A `Dropdown` component with the filtered list of general ledger accounts as options.
 */

export function GeneralLedgerAccountsDropdown({
	accountInfoId,
	onChange,
	accountGroups,
	accountTypes,
	filterFunction,
	unavailableOptions,
	value,
	inputGLAccObjectToOnChange,
	hideGeneralJournalAccounts,
	hideCurrentEarningAccounts,
	openOnly,
	defaultGlAccountId,
	...rest
}: GeneralLedgerAccountsDropdownProps): JSX.Element {
	const throwError = useAsyncError();

	// Provide sane defaults to the filters
	const filter: ChartOfAccountsGeneralLedgerAccountsFilterInput = {
		accountGroups: accountGroups || [
			GeneralLedgerAccountGroupEnum.Asset,
			GeneralLedgerAccountGroupEnum.Liability,
			GeneralLedgerAccountGroupEnum.Equity,
			GeneralLedgerAccountGroupEnum.Revenue,
			GeneralLedgerAccountGroupEnum.Expense,
		],
		accountTypes: accountTypes || [GeneralLedgerAccountTypeEnum.Account, GeneralLedgerAccountTypeEnum.Subaccount],
		openOnly,
		defaultGlAccountId,
	};

	const {loading, data, error} = useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery({
		variables: {accountInfoId, filter},
	});

	if (error) throwError(error);

	const {
		error: coaLinksError,
		data: coaLinksData,
		loading: coaLinksLoading,
	} = useGetChartOfAccountsLinkedQuery({
		variables: {
			accountInfoId,
		},
		skip: !hideGeneralJournalAccounts && !hideCurrentEarningAccounts,
	});
	if (coaLinksError) throwError(coaLinksError);

	function handleChange(selections?: string | string[]) {
		if (onChange) {
			if (inputGLAccObjectToOnChange && !rest.multiple) {
				const glAccount = data?.getChartOfAccountsByAccountInfoId?.glAccounts?.find(gla => gla.id === selections);
				if (glAccount) onChange(glAccount);
			} else onChange(selections);
		}
	}

	let options = data?.getChartOfAccountsByAccountInfoId?.glAccounts;

	if (filterFunction) {
		options = data?.getChartOfAccountsByAccountInfoId?.glAccounts?.filter(filterFunction) || [];
	}
	if (unavailableOptions || hideCurrentEarningAccounts || hideGeneralJournalAccounts) {
		options = options?.filter(glAccount => {
			return ![
				...(unavailableOptions || []),
				...(hideGeneralJournalAccounts
					? [
							selectLinkedAccount(LinkedGlAccountType.Receivables, coaLinksData?.getChartOfAccountsByAccountInfoId?.linkedAccounts)?.id,
							selectLinkedAccount(LinkedGlAccountType.Payables, coaLinksData?.getChartOfAccountsByAccountInfoId?.linkedAccounts)?.id,
					  ]
					: []),
				hideCurrentEarningAccounts
					? selectLinkedAccount(LinkedGlAccountType.CurrentEarnings, coaLinksData?.getChartOfAccountsByAccountInfoId?.linkedAccounts)?.id
					: null,
			].includes(glAccount.id);
		});
	}

	const dropdownOptions = useMemo(() => {
		return (
			options?.reduce((acc, glAccount) => {
				if (glAccount)
					acc.push({
						key: glAccount.id,
						value: glAccount.id,
						text: `${glAccount.code} - ${glAccount.name}`,
						content: (
							<DropdownItem>
								{glAccount.generalLedgerAccountTemplate?.description && (
									<Popup
										trigger={<Icon circular name="info" size="small" style={{float: 'right'}} />}
										content={glAccount.generalLedgerAccountTemplate.description}
									/>
								)}
								{glAccount.code} - {glAccount.name}
							</DropdownItem>
						),
					});
				return acc;
			}, [] as {key: string; text: string; value: string; content: any}[]) || []
		);
	}, [options]);

	return (
		<Dropdown
			{...rest}
			error={rest.error || (!!data?.getChartOfAccountsByAccountInfoId?.glAccounts?.find(gl => gl.id === value && gl.closedDate) && openOnly)}
			fluid
			value={value}
			options={sortBy(dropdownOptions, ['text'])}
			loading={loading || coaLinksLoading || rest.loading}
			placeholder={rest.placeholder || (!dropdownOptions?.length ? 'No accounts found!' : 'Select an account...')}
			disabled={!!error || rest.disabled}
			onChange={(_e, val) => handleChange(val?.value as GlAccDropdownHandleChangeInput)}
			search
		/>
	);
}
