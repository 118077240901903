import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {routes} from '../../../routes';
import {MarkPaymentNsfButton} from '../MarkPaymentNsfButton';
import {useGetSourceDeductionPaymentEditPaymentQuery} from '../SourceDeductionPaymentEditForm/getSourceDeductionPaymentEditPayment';
import {useDeleteSourceDeductionPaymentMutation} from './deleteSourceDeductionPayment';

const d = debug('tacs.web.payroll.components.sourceDeductions.SourceDeductionPaymentEditFormSidebar');

export function SourceDeductionPaymentEditFormSidebar() {
	const {accountInfoId, paymentId} = useParams<typeof routes.types.editSourceDeductionPayment>();
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const [deleteMutation, {loading: mutationLoading}] = useDeleteSourceDeductionPaymentMutation();
	const {push} = useHistory();
	const {data, loading, error} = useGetSourceDeductionPaymentEditPaymentQuery({variables: {id: paymentId}});

	if (error) throw error;

	const isNSF = !!data?.getSourceDeductionPaymentById?.nsf;
	const paymentVersion = data?.getSourceDeductionPaymentById?.version || 0;

	function handleDelete() {
		if (paymentId && paymentVersion) {
			deleteMutation({variables: {id: paymentId, version: paymentVersion}})
				.then(() => {
					push(routes.to.sourceDeductionTable({accountInfoId}));
				})
				.catch(throwError);
		}
	}
	return (
		<List>
			{!isNSF && (
				<List.Item>
					<MarkPaymentNsfButton
						paymentId={paymentId}
						paymentVersion={paymentVersion}
						color="orange"
						fluid
						onClick={() => push(routes.to.sourceDeductionTable({accountInfoId}))}
					/>
				</List.Item>
			)}

			<List.Item>
				<Button disabled={mutationLoading || locked} fluid negative type="button" onClick={handleDelete}>
					Delete
				</Button>
			</List.Item>
		</List>
	);
}
