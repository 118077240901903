import {MoneyInput, TableInput, TFormProps} from '@thx/controls';
import {formatMoney, toMoney} from '@thx/money';
import {debug} from 'debug';
import {PropsWithChildren, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import type {CellProps} from 'react-table';
import {Button, Form, FormInput, Grid, GridColumn, GridRow, Label} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {CreateStatementInput, EditStatementInput, FinancialAccountTypeEnum, StatementStatusEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {FinancialAccountsDropdown} from '../FinancialAccountsDropdown';
import {StatementLine, useStatementColumns} from '../useStatementColumns';
import {useStatementDeleteMutation} from './statementDelete';

const d = debug('tacs.web.documents.components.statement.StatementFormFields');

interface StatementFormFieldsCreate extends TFormProps<CreateStatementInput> {
	called: boolean;
	accountInfoId: string;
	documentType: string | undefined;
}

interface StatementFormFieldsEdit extends TFormProps<EditStatementInput> {
	called: boolean;
	accountInfoId: string;
	documentType: string | undefined;
}

export function StatementFormFields(tFormProps: StatementFormFieldsCreate | StatementFormFieldsEdit) {
	const {locked} = useAccountState();
	const throwError = useAsyncError();
	const {handleSubmit, values, documentType, hasErrors, hasWarnings, renderWarnings, fieldError, setFieldValue, accountInfoId} = tFormProps;

	const [deleteMutation, {loading: mutationLoading}] = useStatementDeleteMutation();

	const onRowDelete = useMemo(
		() => (a: PropsWithChildren<CellProps<StatementLine, any>>) => {
			setFieldValue(
				'statementLines',
				values.statementLines.filter((v, i) => a.row.index !== i),
			);
		},
		[setFieldValue, values.statementLines],
	);
	const {goBack} = useHistory();
	const statementColumns = useStatementColumns({
		onRowDelete,
	});

	const statementLines = values.statementLines.sort((a: any, b: any) => {
		if (a.id && b.id) {
			if (a.date && b.date) {
				if (a.date.toEpochDay() === b.date.toEpochDay()) {
					return a.id > b.id ? -1 : 1;
				}
				return a.date.toEpochDay() > b.date.toEpochDay() ? 1 : -1;
			}

			return a.id > b.id ? 1 : -1;
		}

		return 0;
	});

	function isEditType(data: EditStatementInput | CreateStatementInput): data is EditStatementInput {
		return (data as EditStatementInput).id !== undefined;
	}

	function deleteStatement() {
		if (isEditType(values)) {
			deleteMutation({variables: {id: values.id, version: values.version}})
				.then(() => {
					goBack();
				})
				.catch(throwError);
		}
	}

	const balance = values.closingBalance.subtract(values.openingBalance);

	return (
		<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
			<Grid columns="equal" stackable>
				<GridRow>
					<GridColumn>
						<FormInput
							required
							label={documentType && documentType === 'c4cadeae-7dd5-446d-9e53-258ba504a21b' ? 'Credit Card' : 'Bank Account'}
							error={fieldError('financialAccountId')}
						>
							<FinancialAccountsDropdown
								addNewFinancialAccountButton
								loading={!documentType}
								fluid
								financialAccountsType={
									documentType === 'c4cadeae-7dd5-446d-9e53-258ba504a21b' ? FinancialAccountTypeEnum.CreditCard : FinancialAccountTypeEnum.BankAccount
								}
								accountInfoId={accountInfoId}
								value={values.financialAccountId}
								onChange={val => setFieldValue('financialAccountId', val?.id)}
							/>
						</FormInput>
					</GridColumn>
					<GridColumn>
						<FormInput required label="Opening Balance" error={fieldError('openingBalance')}>
							<MoneyInput value={values.openingBalance} onChange={val => setFieldValue('openingBalance', val)} />
						</FormInput>
					</GridColumn>
					<GridColumn>
						<FormInput required label="Closing Balance" error={fieldError('closingBalance')}>
							<MoneyInput value={values.closingBalance} onChange={val => setFieldValue('closingBalance', val)} />
						</FormInput>
					</GridColumn>
					<GridColumn>
						<FormInput label="Difference">
							<Label basic size="big" content={formatMoney(balance)} />
						</FormInput>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<TableInput<StatementLine>
							name="statementLines"
							values={statementLines}
							columns={statementColumns}
							tableProps={{celled: true, compact: true}}
							setFieldValue={setFieldValue}
							createRow={() => {
								return {
									description: '',
									date: null,
									debit: toMoney(0),
									credit: toMoney(0),
									status: StatementStatusEnum.DocumentRequired,
									document: '',
									reconciled: false,
								};
							}}
							cellProps={{
								style: {
									paddingTop: 0,
									paddingBottom: 0,
								},
							}}
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn textAlign="right">
						<Button
							type="button"
							color="teal"
							onClick={() => {
								setFieldValue('statementLines', [
									...values.statementLines,
									{
										description: '',
										date: null,
										document: '',
										status: 'DocumentRequired',
										reconciled: false,
										debit: toMoney(),
										credit: toMoney(),
									},
								]);
							}}
							content="Add Line"
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>{renderWarnings()}</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						{isEditType(values) && (
							<ConfirmModal
								onConfirm={deleteStatement}
								headerText="Confirm Delete"
								content="Are you sure you want to delete this statement and all existing lines? This can not be undone."
								trigger={
									<Button type="button" floated="left" negative>
										Delete
									</Button>
								}
							/>
						)}
					</GridColumn>
					<GridColumn>
						<Button type="submit" floated="right" positive disabled={mutationLoading || locked}>
							Save
						</Button>
						<Button type="button" floated="right" onClick={() => goBack()}>
							Cancel
						</Button>
					</GridColumn>
				</GridRow>
			</Grid>
		</Form>
	);
}
