import type {LocalDate} from '@js-joda/core';
import {t2Status} from './t2Status';

interface T2Props {
	t2FiledYear?: number | null;
	fiscalStart?: LocalDate | null;
	fiscalEnd?: LocalDate | null;
	t183Signed?: boolean | null;
}
export function t183Status({t2FiledYear, t183Signed, fiscalStart, fiscalEnd}: T2Props) {
	if (!fiscalStart) return 'grey';
	if (!fiscalEnd) return 'grey';

	if (t183Signed) return 'green';
	if (t2Status({t2FiledYear, fiscalStart, fiscalEnd}) === 'green') return 'yellow';
	if (t2Status({t2FiledYear, fiscalStart, fiscalEnd}) === 'yellow') return 'yellow';

	return 'grey';
}
