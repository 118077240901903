import {createPages} from '@imperium/layout';
import {CorporateBinder} from './components/CorporateBinder';
import CorporateBinderActions from './components/CorporateBinderActions';
import {routes} from './routes';

export const routeProps = createPages(routes, {
	corporateBinder: {
		header: 'Corporate Binder',
		content: () => <CorporateBinder />,
		sidebar: [{render: CorporateBinderActions}],
	},
});
