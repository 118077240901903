import {useCan} from '@imperium/auth-client';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useCloneAccountMutation} from './cloneAccount';

export function CloneAccountButton() {
	const {push} = useHistory();
	const {locked} = useAccountState();
	const throwError = useAsyncError();
	const [confirmMutation, {loading: mutationLoading}] = useCloneAccountMutation();
	const [sysadmin] = useCan(Permission.Sysadmin);
	const {accountInfoId, name, friendlyId} = useAccountState();

	if (!sysadmin) return null;

	function onConfirmClicked() {
		if (accountInfoId) {
			confirmMutation({
				variables: {accountInfoId},
				onCompleted: data => {
					toast.success(`Account was cloned to id #${data.cloneAccount.friendlyId}`);
					push(routes.to.account({accountInfoId: data.cloneAccount.id}));
				},
			}).catch(throwError);
		}
	}

	return (
		<ConfirmModal
			onConfirm={onConfirmClicked}
			headerText="Confirm Clone"
			content={`Are you sure you want to clone ${name} (${friendlyId})? This is an experimental feature and should not be used in production.`}
			trigger={
				<Button disabled={mutationLoading || locked} fluid negative>
					Clone Account
				</Button>
			}
		/>
	);
}
