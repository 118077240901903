import {Icon} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as documentRoutes} from '../../../../documents/routes';

interface Props {
	accountInfoId: string;
	unsortedFiles?: number | undefined;
	borderRight?: boolean;
}

export function UnsortedDocumentsCell({accountInfoId, unsortedFiles, borderRight}: Props) {
	return (
		<LinkedTableCell
			to={documentRoutes.to.draftDocuments({accountInfoId})}
			style={{color: '#121212', borderRight: borderRight ? '1px solid #dcdcdc' : undefined, textAlign: 'center'}}
			value={unsortedFiles || <Icon name="check circle" color="green" />}
			selectable
			linkStyle={{paddingTop: 0, marginTop: 5}}
		/>
	);
}
