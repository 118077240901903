import {Grid, GridColumn, GridRow, SegmentGroup} from 'semantic-ui-react';
import type {DocumentGroupEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {CorporateBinderTableRow} from '../CorporateBinderTableRow';
import {useGetIncorporationDocumentsQuery} from './getIncorporationDocuments';

interface Props {
	documentTypeGroup: DocumentGroupEnum;
}

export function CorporateBinderTable({documentTypeGroup}: Props) {
	const throwError = useAsyncError();
	const {data: incorporationData, error: incorporationError} = useGetIncorporationDocumentsQuery({
		variables: {documentTypeGroup},
	});

	if (incorporationError) throwError(incorporationError);

	return (
		<Grid>
			<GridRow>
				<GridColumn>
					<SegmentGroup>
						{incorporationData?.getDocumentTypesByGroup?.map(type => {
							return <CorporateBinderTableRow typeName={type.name} typeId={type.id} key={type.id} />;
						})}
					</SegmentGroup>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
