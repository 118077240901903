import {useFetch} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import {toLocalDate} from '@thx/date';
import {env} from '@thx/env';
import {sequential} from '@thx/promise-sequential';
import {type ChangeEvent, type DragEvent, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
	Button,
	Divider,
	Form,
	FormInput,
	Grid,
	GridColumn,
	GridRow,
	Header,
	Icon,
	Segment,
	Table,
	TableBody,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {ReceiveMethodEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../accounts/state';
import {defaults} from '../../../defaults';
import {useGetDocumentsTableQuery} from '../../../documents/components/documents/DocumentsTable/getDocumentsTable';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes as documentRoutes} from '../../../documents/routes';
import type {routes} from '../../routes';

interface Props {
	typeName: string;
	typeId: string;
}
enum FileStatusEnum {
	Pending = 'Pending',
	Success = 'Success',
	Failure = 'Failure',
	Duplicate = 'Duplicate',
	Uploading = 'Uploading',
}
type Upload = {
	status: FileStatusEnum;
	file: File;
	date: LocalDate;
	type: string;
	vendor: string;
};

export function CorporateBinderTableRow(props: Props) {
	const {locked} = useAccountState();
	const unsortedFileUploadUrl = env.getString('unsortedFileUploadUrl', defaults.unsortedFileUploadUrl);
	const [uploads, setUploads] = useState<Upload[]>([]);
	const [highlight, setHighlight] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const maxDocUploadSizeMb = 15;
	const fetch = useFetch();
	const {accountInfoId} = useParams<typeof routes.types.corporateBinder>();
	const throwError = useAsyncError();
	const {data, error} = useGetDocumentsTableQuery({
		variables: {
			accountInfoId,
			filters: {
				startDate: LocalDate.now().withYear(2000),
				endDate: LocalDate.now(),
				type: props.typeId,
			},
		},
	});

	if (error) throwError(error);

	function cardColor(status: FileStatusEnum) {
		switch (status) {
			case FileStatusEnum.Pending:
				return undefined;
			case FileStatusEnum.Success:
				return 'green';
			case FileStatusEnum.Failure:
				return 'red';
			case FileStatusEnum.Duplicate:
				return 'orange';
			case FileStatusEnum.Uploading:
				return 'teal';
			default:
				return undefined;
		}
	}

	function fileListToArray(list: FileList) {
		const array: File[] = [];
		for (let i = 0; i < list.length; i++) {
			array.push(list[i]);
		}
		return array;
	}

	function addFilesToUploadList(files: File[]) {
		const deDuplicatedFiles = files.filter(fileToUpload => !uploads.some(upload => upload.file.name === fileToUpload.name));
		const filesAndUploads = uploads.concat(
			deDuplicatedFiles.map(file => {
				return {file, status: FileStatusEnum.Pending, date: LocalDate.now(), vendor: '', type: props.typeId};
			}),
		);
		setUploads(filesAndUploads);
	}

	function onFilesAdded(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files) {
			addFilesToUploadList(fileListToArray(event.target.files));
		}
	}

	function onDrop(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();

		if (disabled) return;

		if (event.dataTransfer) {
			addFilesToUploadList(fileListToArray(event.dataTransfer.files));
			setHighlight(false);
		}
	}

	function onDragOver(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();

		if (disabled) return;

		setHighlight(true);
	}

	function onDragLeave() {
		setHighlight(false);
	}

	function openFileDialog() {
		fileInputRef.current?.click();
	}

	async function onSubmit() {
		await sequential(
			uploads.map((upload, index) => async () => {
				const currentFile = uploads;
				if (upload.file.size > maxDocUploadSizeMb * 1048576) {
					currentFile[index].status = FileStatusEnum.Failure;
					setUploads([...currentFile]);
				} else if (upload.status === FileStatusEnum.Pending) {
					currentFile[index].status = FileStatusEnum.Uploading;
					setUploads([...currentFile]);

					const formData = new FormData();
					formData.append('file', upload.file);

					try {
						const response = await fetch(unsortedFileUploadUrl, {
							method: 'POST',
							mode: 'cors',
							credentials: 'include',
							headers: {
								Account: accountInfoId,
								method: ReceiveMethodEnum.Website,
								documentDate: LocalDate.now().toEpochDay().toString(),
								documentType: props.typeId,
								documentVendor: upload.vendor,
							},
							body: formData,
						});

						if (response.status === 200) {
							currentFile[index].status = FileStatusEnum.Success;
							setUploads([...currentFile]);
						} else if (response.status === 522) {
							currentFile[index].status = FileStatusEnum.Duplicate;
							setUploads([...currentFile]);
						} else {
							currentFile[index].status = FileStatusEnum.Failure;
							setUploads([...currentFile]);
						}
					} catch (err) {
						currentFile[index].status = FileStatusEnum.Failure;
						setUploads([...currentFile]);
					}
				}
			}),
		);
	}
	function editUploadArray(upload: Upload, {date, vendor}: {vendor?: string; date?: LocalDate | null}) {
		return uploads.map(old => {
			if (old.file === upload.file) {
				if (vendor) {
					return {
						...old,
						vendor,
					};
				}
				if (date) {
					return {
						...old,
						date: date || LocalDate.now(),
					};
				}
			}
			return old;
		});
	}

	const documentsExist = data?.getDocumentsByAccount && data?.getDocumentsByAccount.length > 0;

	return (
		<Segment onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} style={{backgroundColor: highlight ? '#e0e1e2' : undefined}}>
			<Header size="small">
				<Icon color={documentsExist ? 'green' : 'orange'} name={documentsExist ? 'check circle' : 'warning circle'} style={{display: 'inline'}} />
				{props.typeName}
				<Icon name="upload" color="grey" style={{float: 'right', display: 'inline', cursor: 'pointer'}} onClick={openFileDialog} />
				<input
					style={{display: 'none'}}
					ref={fileInputRef}
					type="file"
					multiple
					onChange={onFilesAdded}
					accept=".pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .heic, .avif"
				/>
			</Header>
			{documentsExist && (
				<Table basic="very" selectable compact fixed>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>Date</TableHeaderCell>
							<TableHeaderCell>Vendor</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data?.getDocumentsByAccount?.map(document => {
							return (
								<TableRow key={document.id}>
									<LinkedTableCell
										to={documentRoutes.to.documentViewer({accountInfoId, documentId: document.id, tab: '0'})}
										value={toLocalDate(document.date).toString()}
									/>
									<LinkedTableCell
										to={documentRoutes.to.documentViewer({accountInfoId, documentId: document.id, tab: '0'})}
										value={document.vendor?.name}
									/>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
			{uploads.length > 0 && (
				<Form size="small">
					<Divider style={{paddingBottom: 5}} />
					<Grid columns={5}>
						{uploads.map(upload => {
							return (
								<GridRow key={upload.file.name} style={{padding: 0, margin: 0}}>
									<GridColumn width={2}>
										<FormInput>
											<Icon name="file" color={cardColor(upload.status)} />
											{upload.status}
										</FormInput>
									</GridColumn>
									<GridColumn width={4}>
										<FormInput>{upload.file.name}</FormInput>
									</GridColumn>
									<GridColumn width={3}>
										<FormInput>
											<LocalDatePicker
												maxDate={LocalDate.now()}
												value={upload.date}
												onChange={date => setUploads(editUploadArray(upload, {date}))}
												disabled={upload.status !== FileStatusEnum.Pending}
											/>
										</FormInput>
									</GridColumn>
									<GridColumn width={5}>
										<FormInput>
											<VendorDropdown
												addNewVendorButton
												value={upload.vendor}
												accountInfoId={accountInfoId}
												fluid
												onChange={vendor => setUploads(editUploadArray(upload, {vendor}))}
												disabled={upload.status !== FileStatusEnum.Pending}
												size="small"
											/>
										</FormInput>
									</GridColumn>
									<GridColumn width={2} floated="right">
										<Button
											icon="trash"
											size="small"
											type="button"
											negative
											disabled={upload.status !== FileStatusEnum.Pending}
											onClick={() => setUploads(uploads.filter(currentFiles => currentFiles.file.name !== upload.file.name))}
											floated="right"
										/>
									</GridColumn>
								</GridRow>
							);
						})}
						<Divider style={{margin: 0}} />
						<GridRow style={{paddingTop: 5, paddingBottom: 5, margin: 0}}>
							<GridColumn width={16}>
								<Button
									positive
									type="button"
									onClick={() => onSubmit()}
									floated="right"
									disabled={uploads.length === 0 || !!uploads.find(upload => upload.vendor === '' || locked)}
								>
									Submit
								</Button>
							</GridColumn>
						</GridRow>
					</Grid>
				</Form>
			)}
		</Segment>
	);
}
