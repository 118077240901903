import type {LocalDate} from '@js-joda/core';
import {LocalDatePicker, useTForm} from '@thx/controls';
import {localDateSchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useState} from 'react';
import {Button, ButtonProps, Divider, Form, FormInput} from 'semantic-ui-react';
import {object} from 'yup';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../../accounts/state';
import {useMarkSourceDeductionPaymentNsfMutation} from './markSourceDeductionPaymentNSF';

const d = debug('tacs.web.payroll.components.sourceDeductions.MarkPaymentNsfButton');

const markPaymentNsfButtonValidation = object().shape({
	date: localDateSchemaType().required('The date is required'),
});

interface MarkPaymentNsfButtonProps extends Omit<ButtonProps, 'onClick'> {
	onClick: () => void;
	paymentId: string;
	paymentVersion: number;
}

export function MarkPaymentNsfButton(props: MarkPaymentNsfButtonProps) {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {onClick, paymentId, paymentVersion, ...rest} = props;
	const [markNsfMutation, {loading: mutationLoading}] = useMarkSourceDeductionPaymentNsfMutation();
	const [markClicked, setMarkClicked] = useState(false);
	const {values, setFieldValue, handleSubmit, fieldError, resetForm} = useTForm<{date: LocalDate}>({
		validationSchema: markPaymentNsfButtonValidation,
		initialValues: {},
		onSubmit: ({date}) => {
			markNsfMutation({variables: {id: paymentId, version: paymentVersion, date}})
				.then(() => {
					onClick && onClick();
				})
				.catch(throwError);
		},
	});

	if (markClicked) {
		return (
			<Form onSubmit={handleSubmit}>
				<Divider />
				<FormInput autoFocus fluid label="Date of NSF" action error={fieldError('date')}>
					<LocalDatePicker todayButton="Today" value={values.date} onChange={val => setFieldValue('date', val)} />
				</FormInput>
				<Button onClick={() => setMarkClicked(false)}>Cancel</Button>
				<Button disabled={mutationLoading || locked} positive floated="right" type="submit">
					Save
				</Button>
				<Divider />
			</Form>
		);
	}

	return (
		<Button
			{...rest}
			type="button"
			onClick={() => {
				setMarkClicked(true);
				resetForm();
			}}
		>
			Mark as NSF
		</Button>
	);
}
