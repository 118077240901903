import {useCan} from '@imperium/auth-client';
import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {Permission} from '~core/graphql';
import {needsPermission} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {AgentActivityButton} from '../user/components/AgentActivityButton';
import {AgentHeader} from '../user/components/AgentHeader';
import {ChangeHourlyPayButton} from './components/ChangeHourlyPayButton';
import {ChangeVacationPayRateButton} from './components/ChangeVacationPayRateButton';
import {TimeCardCreateButton} from './components/TimeCardCreateButton';
import {TimeCardStatsTable} from './components/TimeCardStatsTable';
import {routes} from './routes';

const AgentInvitationForm = loadable(() => import('../registration/components/AgentInvitationForm'));
const OfficeOverview = loadable(() => import('./components/OfficeOverview'));
const OfficeCreate = loadable(() => import('./components/OfficeCreate'));
const OfficeEdit = loadable(() => import('./components/OfficeEdit'));
const Organizations = loadable(() => import('./components/Organizations'));
const OrganizationCreate = loadable(() => import('./components/OrganizationCreate'));
const OrganizationEdit = loadable(() => import('./components/OrganizationEdit'));
const OrganizationOverview = loadable(() => import('./components/OrganizationOverview'));
const TimeCards = loadable(() => import('./components/TimeCards'));
const TimeCardsEdit = loadable(() => import('./components/TimeCardsEdit'));

export const routeProps = createPages(routes, {
	agentUserAdd: {
		header: 'Invite Agent',
		content: () => <AgentInvitationForm />,
	},
	officeOverview: {
		header: 'Office Overview',
		content: () => <OfficeOverview />,
		sidebar: [
			{
				text: 'Edit Office',
				to: data => routes.to.officeEdit({officeId: data.params.officeId, organizationId: data.params.organizationId}),
				color: 'green',
			},
			{
				text: 'Invite Agent',
				to: data => routes.to.agentUserAdd({officeId: data.params.officeId, organizationId: data.params.organizationId}),
				color: 'blue',
			},
		],
	},
	officeCreate: {
		header: 'Create Office',
		content: () => <OfficeCreate />,
	},
	officeEdit: {
		header: 'Edit Office',
		content: () => <OfficeEdit />,
	},
	organizations: {
		header: 'Organizations',
		content: () => <Organizations />,
		sidebar: [{text: 'Create Organization', to: routes.to.organizationCreate, color: 'green'}],
	},
	organizationOverview: {
		header: 'Organization Overview',
		content: () => <OrganizationOverview />,
		sidebar: [
			{text: 'Edit Organization', to: data => routes.to.organizationEdit({organizationId: data.params.organizationId}), color: 'green'},
			{text: 'Create Office', color: 'blue', to: data => routes.to.officeCreate({organizationId: data.params.organizationId})},
		],
	},
	organizationCreate: {
		header: 'Create Organization',
		content: () => <OrganizationCreate />,
	},
	organizationEdit: {
		header: 'Edit Organization',
		content: () => <OrganizationEdit />,
	},
	timeCards: {
		header: data => <AgentHeader agentId={data.params.agentId} title="Time Cards" />,
		content: () => <TimeCards />,
		sidebar: [
			{render: TimeCardCreateButton, visible: {...needsPermission([Permission.ReadAllAccount])}},
			{render: ChangeHourlyPayButton, visible: {...needsPermission(Permission.EditRates)}},
			{render: ChangeVacationPayRateButton, visible: {...needsPermission([Permission.EditRates])}},
			{render: TimeCardStatsTable, visible: {...needsPermission([Permission.ReadAllAccount])}},
		],
	},
	timeCardEdit: {
		header: data => <AgentHeader agentId={data.params.agentId} title="Time Card" />,
		content: () => <TimeCardsEdit />,
		sidebar: [
			{
				render: ChangeHourlyPayButton,
				visible: {...needsPermission([Permission.EditRates])},
			},
			{
				render: ChangeVacationPayRateButton,
				visible: {...needsPermission([Permission.EditRates])},
			},
			{
				render: AgentActivityButton,
				stateSelectorHook: [useAccountState],
				permissionSelectorHook: [
					data => {
						const [manageAssignedAgents] = useCan([{permission: Permission.ManageAssignedAgents, data: {agentId: data.route.path[1] || null}}]);
						return {manageAssignedAgents};
					},
				],
				visible: {...needsPermission([Permission.ManageAssignedAgents])},
			},
			{
				render: TimeCardStatsTable,
				visible: {...needsPermission([Permission.ReadAllAccount])},
			},
		],
	},
});
