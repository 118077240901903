import {Message} from 'semantic-ui-react';

export default function CorporateBinderActions() {
	return (
		<Message
			content="Files can be uploaded to each category by dragging and dropping the file on the category name."
			header="Corporate Binder Tips"
		/>
	);
}
