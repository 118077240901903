import {formatMoney, toMoney} from '@thx/money';
import type Money from 'js-money';
import {Icon} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as receivableRoutes} from '../../../../receivables/routes';

interface Props {
	accountInfoId: string;
	balance?: Money | null;
	unsortedFiles?: number | undefined;
	borderRight?: boolean;
}

export function BalanceCell({accountInfoId, balance, borderRight}: Props) {
	return (
		<LinkedTableCell
			to={receivableRoutes.to.transactions({accountInfoId})}
			style={{color: '#121212', borderRight: borderRight ? '1px solid #dcdcdc' : undefined, textAlign: 'center'}}
			value={
				toMoney(balance).isZero() ? (
					<Icon name="check circle" color="green" />
				) : (
					<span style={{color: 'red', fontWeight: 'bold'}}>{formatMoney(toMoney(balance))}</span>
				)
			}
			selectable
			linkStyle={{paddingTop: 0, marginTop: 5}}
		/>
	);
}
