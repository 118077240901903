import {TForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {object} from 'yup';
import {AddressInput} from '~common/components/AddressInput';
import {addressEditSchema, AddressEditSchemaType} from '~lib/addressSchema';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useEditAccountInfoAddressMutation} from './editAccountInfoAddress';
import {useGetEditAddressQuery} from './getEditAddress';

const d = debug('tacs.web.accounts.components.accounts.EditAddress');

interface EditAddressProps {
	onSave?: () => void;
}

const editAddressValidation = object().shape({
	address: addressEditSchema().nullable().notRequired(),
});

export interface EditAddressValidationType {
	address?: AddressEditSchemaType | null;
}

export function EditAddress() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const [editClicked, setEditClicked] = useState(false);
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const {data, error} = useGetEditAddressQuery({variables: {accountInfoId}});
	const [editMutation, {loading: mutationLoading}] = useEditAccountInfoAddressMutation();

	const handleSubmitClick = (data1: EditAddressValidationType) => {
		if (data1.address)
			editMutation({
				variables: {
					accountInfoId,
					data: data1.address,
				},
				refetchQueries: ['getSelfEmployedAccountInfoInformation', 'getCorporateAccountByAccountInfoId'],
			})
				.then(() => {
					toast.success('Address saved.');
					setEditClicked(false);
				})
				.catch(throwError);
	};

	if (error) throwError(error);

	if (editClicked) {
		return (
			<TForm<EditAddressValidationType>
				initialValues={{
					address: data?.getAccountInfoById?.address || null,
				}}
				validationSchema={editAddressValidation}
				onSubmit={handleSubmitClick}
			>
				{({setFieldValue, handleSubmit, values, hasErrors, hasWarnings, handleBlur, fieldError, resetForm}) => {
					return (
						<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
							<FormInput>
								<AddressInput
									name="address"
									setFieldValue={setFieldValue}
									value={values?.address || undefined}
									onBlur={handleBlur}
									fieldError={fieldError}
									columnCount={2}
								/>
							</FormInput>
							<Button positive type="submit" floated="right">
								Save
							</Button>
							<Button
								disabled={mutationLoading || locked}
								type="button"
								onClick={() => {
									resetForm();
									setEditClicked(false);
								}}
							>
								Cancel
							</Button>
						</Form>
					);
				}}
			</TForm>
		);
	}

	return (
		<Button fluid color="blue" disabled={locked} onClick={() => setEditClicked(true)}>
			Edit Address
		</Button>
	);
}
