import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetAgentDropdownQuery} from '~common/components/AgentDropdown/getAgentDropdown';
import type {AgentRole} from '~core/graphql';
import {getFullName} from '~lib/getFullName';

const d = debug('tacs.web.common.components.AgentDropdown');

interface AgentDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (agentId: string) => void;
	roles: AgentRole[];
	defaultUserId?: string | null;
	active?: boolean;
}

export function AgentDropdown(props: AgentDropdownProps) {
	const {onChange, loading, defaultUserId, value, ...rest} = props;
	const {
		data,
		loading: queryLoading,
		error,
	} = useGetAgentDropdownQuery({
		variables: {roles: props.roles, active: props.active === undefined ? true : props.active},
		onCompleted: result => {
			const agent = result?.getAgentsByRoles?.find(a => defaultUserId === a.authorizedUser?.id);
			if (agent?.id && onChange) onChange(agent.id);
		},
	});

	const handleChange = (val?: string) => {
		if (onChange) onChange(val || '');
	};

	const options = data?.getAgentsByRoles?.map(agent => {
		return {key: agent.id, value: agent.id, text: getFullName(agent)};
	});

	return (
		<Dropdown
			search
			{...rest}
			value={value}
			error={!!error}
			loading={loading || queryLoading}
			options={options || []}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
