import {camelCase, startCase} from 'lodash-es';

export function createDropdownOptionsFromEnum(enumData: Record<string, string | number>, startCaseKey?: boolean) {
	return Object.keys(enumData).map(key => {
		return {
			text: startCase(key),
			key,
			value: startCaseKey ? key : camelCase(key),
		};
	});
}
