import {Tab} from 'semantic-ui-react';
import {Dissolution} from './Dissolution';
import {Incorporation} from './Incorporation';
import {Operation} from './Operation';

export function CorporateBinder() {
	return (
		<Tab
			panes={[
				{
					menuItem: 'Incorporation',
					render: Incorporation,
				},
				{
					menuItem: 'Operation',
					render: Operation,
				},
				{
					menuItem: 'Dissolution',
					render: Dissolution,
				},
			]}
		/>
	);
}
