import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {Loading} from '~common/components';
import {getFullName} from '~lib/getFullName';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useReemployCorporateEmployeeMutation} from '../EndEmploymentForm/reemployCorporateEmployee';
import {useGetEmployeeAddSidebarPreviousEmployeesQuery} from './getEmployeeAddSidebarPreviousEmployees';

const d = debug('tacs.web.accounts.components.employees.EmployeeAddSidebarPreviousEmployeeList');

export function EmployeeAddSidebarPreviousEmployeeList() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.employees>();

	const {data, loading, error} = useGetEmployeeAddSidebarPreviousEmployeesQuery({variables: {accountInfoId}});
	const [reemployCorporateEmployeeMutation, {loading: mutationLoading}] = useReemployCorporateEmployeeMutation();

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const employees = data.getEmployeesByAccountInfoId;

	const onReEmployClick = (employeeId: string, employeeVersion: number) => {
		reemployCorporateEmployeeMutation({
			variables: {employee: {id: employeeId, version: employeeVersion}},
		})
			.then(() => push(routes.to.viewEmployee({accountInfoId, employeeId})))
			.catch(throwError);
	};

	return (
		<List>
			<List.Header as="h3">Choose From Previous Employees</List.Header>
			{employees?.length
				? employees.map(employee => {
						return (
							<List.Item key={employee.id}>
								<Button disabled={mutationLoading || locked} fluid color="blue" onClick={() => onReEmployClick(employee.id, employee.version)}>
									Re-employ {getFullName(employee.contact)}
								</Button>
							</List.Item>
						);
				  })
				: 'none available...'}
		</List>
	);
}
