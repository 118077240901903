import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {AgentDropdown} from '~common/components/AgentDropdown';
import {AgentRole} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useAccountState} from '../../../state';
import {useEditDefaultAgentMutation} from './editDefaultAgent';
import {useGetEditDefaultAgentLazyQuery} from './getEditDefaultAgent';

const d = debug('tacs.web.accounts.components.accounts.EditDefaultAgent');

export function EditDefaultAgent() {
	const throwError = useAsyncError();
	const {locked} = useAccountState();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditDefaultAgentMutation();
	const [getQuery, {data, loading, error}] = useGetEditDefaultAgentLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {defaultAgentId: data?.getAccountInfoById?.defaultAgent?.id || ''},
		onSubmit: formValues => {
			editMutation({
				variables: {accountInfoId, defaultAgentId: formValues.defaultAgentId},
			})
				.then(() => {
					toast.success('Default Agent Saved');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Default Agent">
					<AgentDropdown
						fluid
						selection
						value={values.defaultAgentId}
						onChange={val => setFieldValue('defaultAgentId', val)}
						roles={[AgentRole.Workstation]}
					/>
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					disabled={mutationLoading || locked}
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" disabled={locked} onClick={() => setEditClicked(true)}>
			Change Default Agent
		</Button>
	);
}
