import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import Operations from '~common/components/GeneralLedgerAccountsDropdown/getGeneralLedgerAccountsDropdownChartOfAccounts.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter?: Types.InputMaybe<Types.ChartOfAccountsGeneralLedgerAccountsFilterInput>;
}>;

export type GetGeneralLedgerAccountsDropdownChartOfAccountsQuery = {
	getChartOfAccountsByAccountInfoId?: {
		id: string;
		version: number;
		glAccounts?: Array<{
			id: string;
			version: number;
			name: string;
			code: number;
			type: Types.GeneralLedgerAccountTypeEnum;
			group: Types.GeneralLedgerAccountGroupEnum;
			closedDate?: LocalDate | null;
			generalLedgerAccountTemplate?: {id: string; description?: string | null} | null;
		}> | null;
	} | null;
};

export type GetGeneralLedgerAccountsDropdownChartOfAccountsType = {
	id: string;
	version: number;
	glAccounts?: Array<{
		id: string;
		version: number;
		name: string;
		code: number;
		type: Types.GeneralLedgerAccountTypeEnum;
		group: Types.GeneralLedgerAccountGroupEnum;
		closedDate?: LocalDate | null;
		generalLedgerAccountTemplate?: {id: string; description?: string | null} | null;
	}> | null;
};

/**
 * __useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery__
 *
 * To run a query within a React component, call `useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetGeneralLedgerAccountsDropdownChartOfAccountsQuery,
		GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetGeneralLedgerAccountsDropdownChartOfAccountsQuery, GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables>(
		Operations,
		options,
	);
}
export function useGetGeneralLedgerAccountsDropdownChartOfAccountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetGeneralLedgerAccountsDropdownChartOfAccountsQuery,
		GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetGeneralLedgerAccountsDropdownChartOfAccountsQuery, GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables>(
		Operations,
		options,
	);
}
export type GetGeneralLedgerAccountsDropdownChartOfAccountsQueryHookResult = ReturnType<
	typeof useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery
>;
export type GetGeneralLedgerAccountsDropdownChartOfAccountsLazyQueryHookResult = ReturnType<
	typeof useGetGeneralLedgerAccountsDropdownChartOfAccountsLazyQuery
>;
export type GetGeneralLedgerAccountsDropdownChartOfAccountsQueryResult = Apollo.QueryResult<
	GetGeneralLedgerAccountsDropdownChartOfAccountsQuery,
	GetGeneralLedgerAccountsDropdownChartOfAccountsQueryVariables
>;
